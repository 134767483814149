<template>
  <div class="hello">
    <h1>{{ welcome }}</h1>
    <h3>Our website is currently under maintenance, we will announce on <a href="https://www.facebook.com/miramichipool">Facebook</a> when it's live!</h3>
    <h3>Visit our <a href="https://www.facebook.com/miramichipool">Facebook</a> for information on our products and services!</h3>
    <div id="socials">
      <div class="social">
        <a href="https://www.facebook.com/miramichipool" target="_blank"><img src="../assets/fb-miramichi-pool.png" /></a>
      </div>
      <div class="social">
        <a href="https://www.instagram.com/miramichipool/" target="_blank"><img src="../assets/instagram-miramichi-pool.png" /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiramichiPool',
  props: {
    welcome: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 0;
  font-size:35px;
}
h3 {
  margin: 20px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #3EA0C4;
}
#socials{
  display:block;
  width:30%;
  min-width:150px;
  margin:50px auto auto;
}
.social{
  display:inline-block;
  margin: 0 10px;
}
.social img{
  width:48px;
}

</style>
