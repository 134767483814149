<template>
  <img id="logo" alt="Vue logo" src="./assets/logo.png">
  <MiramichiPool welcome="Welcome to Miramichi Pool!"/>
</template>

<script>
import MiramichiPool from './components/MiramichiPool.vue'

export default {
  name: 'App',
  components: {
    MiramichiPool
  }
}
</script>

<style>
@font-face {
  font-family: Avenir;
  src: url("./assets/fonts/Avenir.otf");
}

body{
  background-color:#131516;
}
#logo{
  width:30%;
  min-width:250px;
  max-width:500px;
  margin-top: -35px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #d8d4cf;
  margin-top: 30px;
}
</style>
